import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Container from '@layout/Container'
import FlexComponents from '@layout/FlexComponents'
import { formatTemplateData } from '@utils/templates'
import HopsUrl from '@images/placeholders/illustration-hops.png'
import { camelCase } from '@utils/strings'
import { ComponentSpacer } from '@layout/FlexComponents'

const Post = ({ data }) => {
  const {
    wpPost: {
      contentPost: {
        flexibleContent,
        featuredImage,
        heroContent: heroFields,
        ...pageData
      },
      eventDetails,
      categories: { nodes: categories },
      title,
      date,
      seo,
      uri,
    },
    wp: {
      themeDefaults: {
        contentThemeDefaults: { illustrations },
      },
    },
  } = data
  seo.path = uri

  const template = {
    title,
    heroFields,
    activeNavBarSlug: 'events-stories',
    activeSlug: 'events-stories',
    designations: ['isSinglePost'],
    heroType: 'post',
    theme: 'light',
    seo,
    meta: {
      date,
      categories,
      image:
        illustrations[
          Math.floor(Math.random() * Math.floor(illustrations.length))
        ],
    },
  }

  const isEvent =
    categories.some(({ slug }) => {
      return slug === 'events'
    }) && eventDetails

  if (isEvent) {
    template.designations.push('isEvent')
    template.meta = {
      ...template.meta,
      eventDate: eventDetails.eventDate,
      eventDateDescription: eventDetails.eventDateDescription,
      eventLocation: eventDetails.eventLocation,
      eventTicketsLink: eventDetails.eventTicketsLink,
      eventDetails: eventDetails.eventDetails,
    }
  }

  return (
    <Container template={formatTemplateData(template)}>
      {flexibleContent &&
        flexibleContent.map(({ __typename, ...componentProps }, index) => {
          const layout = camelCase(
            __typename.split('FlexibleContent_').slice(-1)[0]
          )
          return (
            <FlexComponents
              key={`${layout}-${index}`}
              layout={layout}
              componentProps={componentProps}
            />
          )
        })}
      <ComponentSpacer />
    </Container>
  )
}

export default Post

export const postQuery = graphql`
  fragment TeaserPostFields on WpPost {
    id
    title
    databaseId
    date(formatString: "MMMM D, YYYY")
    uri
    topCategory {
      name
      color
    }
    eventDetails {
      eventDate
    }
    contentPost {
      featuredImage {
        localFile {
          childImageSharp {
            # TODO: Alter sizing
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }

  query BlogPostByID($id: String!) {
    wp {
      themeDefaults {
        contentThemeDefaults {
          illustrations {
            localFile {
              childImageSharp {
                # TODO: Alter sizing
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mediaDetails {
              width
              height
            }
          }
        }
      }
    }
    wpPost(id: { eq: $id }) {
      id
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
          slug
          databaseId
          wpParent {
            databaseId
          }
        }
      }
      eventDetails {
        eventDate
        eventDateDescription
        eventDetails {
          detailType
          fieldGroupName
          link {
            target
            title
            url
          }
          text
        }
        eventLocation {
          streetAddress
        }
        eventTicketsLink {
          url
          title
          target
        }
      }
      contentPost {
        heroContent {
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                # TODO: Alter sizing
                fluid(maxWidth: 1664) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        featuredImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1664) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        flexibleContent {
          ... on WpPost_Contentpost_FlexibleContent_ColumnsBlock {
            __typename
            columns {
              url
              selectedfields
              heading
              fieldGroupName
              content
            }
            textContent {
              content
              fieldGroupName
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                text
                type
              }
              heading
              selectedFields
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_FormBlock {
            __typename
            formId
            textContent {
              selectedFields
              heading
              content
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                file {
                  id
                  sourceUrl
                }
                text
                type
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_Heading {
            __typename
            heading
            hasMedia
            media {
              videoUrl
              mediaType
              ratio
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1280, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_ListBlock {
            containerWidth
            textContent {
              selectedFields
              heading
              content
            }
            list {
              label
              content
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_ListBlock {
            containerWidth
            textContent {
              selectedFields
              heading
              content
            }
            list {
              label
              content
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_Iframe {
            __typename
            embed
            containerWidth
          }
          ... on WpPost_Contentpost_FlexibleContent_Gallery {
            caption
            containerWidth
            featuredStyle
            imageAlign
            gallery {
              localFile {
                publicURL
                childImageSharp {
                  # TODO: Alter sizing
                  fluid(maxWidth: 1920, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_MediaGrid {
            __typename
            mediaItems {
              media {
                videoUrl
                mediaType
                ratio
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                  mediaDetails {
                    width
                    height
                  }
                }
              }
              caption
            }
            containerWidth
          }
          ... on WpPost_Contentpost_FlexibleContent_Quote {
            content
            author
            authorMeta
          }
          ... on WpPost_Contentpost_FlexibleContent_RelatedPosts {
            __typename
            postType
            hasFeatured
            containerWidth
            posts {
              ...TeaserPostFields
            }
            pages {
              heading
              backgroundColor
              imageType
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_Slider {
            __typename
            sliderImages {
              id
              caption
              altText
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    aspectRatio
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_Spacer {
            __typename
          }
          ... on WpPost_Contentpost_FlexibleContent_Text {
            __typename
            containerWidth
            contentAlign
            typeSize
            textContent {
              content
              fieldGroupName
              heading
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                file {
                  id
                  sourceUrl
                }
                text
                type
              }
              selectedFields
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_TextWithRelatedPost {
            postType
            textContent {
              selectedFields
              heading
              content
            }
            posts {
              ...TeaserPostFields
            }
            pages {
              heading
              backgroundColor
              imageType
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_Contentpost_FlexibleContent_TextBanner {
            bannerText
            images {
              caption
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      seo {
        title
        twitterTitle
        twitterDescription
        twitterImage {
          description
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphDescription
        metaRobotsNoindex
        metaRobotsNofollow
        metaDesc
      }
    }
  }
`
